import { For, createEffect, Show } from "solid-js";
import { createSlider } from "solid-slider";
import { Banner } from "../data/banners";
import { openSearchPage, selectASearchCategory } from "../screens/home/search";
import { openVoucherL2 } from "../screens/screens";
import { PhosphorIcon } from "./icons";
import { A } from "@solidjs/router";

export function Banners(props: {
  banners?: Banner[];
  showHeader?: boolean;
  showFooter?: boolean;
  perView?: number;
  mode?: string;
}) {
  const today = new Date();

  const activeBanners = props.banners?.filter((banner) => {
    if (banner.type === "static") {
      return true;
    }
    const startDate = new Date(banner.start_date || "");
    const endDate = new Date(banner.end_date || "");
    return today >= startDate && today <= endDate;
  });

  // Handling the logic when there are exactly two active banners
  let bannersToDisplay = activeBanners;
  if (activeBanners?.length === 2) {
    bannersToDisplay = Array(2).fill(activeBanners).flat();
  }

  const [slider, { current, next, prev, moveTo }] = createSlider({
    loop: (bannersToDisplay?.length ?? 0) > 1,
    slides: {
      perView: props.perView
        ? props.perView
        : bannersToDisplay?.length ?? 0 <= 1
          ? 1
          : 1.08,
      origin: "auto",
      spacing: 0,
    },
  });

  const autoScroll = () => {
    if ((bannersToDisplay?.length ?? 0) <= 1) return;
    const interval = setInterval(() => {
      next();
    }, 3000);
    return () => clearInterval(interval);
  };

  createEffect(autoScroll);

  return (
    <Show when={(bannersToDisplay?.length ?? 0) >= 0} fallback={<></>}>
      <Show when={props.showHeader} fallback={<></>}>
        <div class="mb-6 flex items-center gap-4 px-4 lg:px-0">
          <div class=" text-nowrap text-h4 text-basePrimaryDark">
            Offers & more
          </div>
          <hr class="w-full" />
          <div class="flex gap-2">
            <button
              class="flex h-7 w-7 cursor-pointer items-center justify-center rounded-lg border border-baseTertiaryDark p-2 hover:border-darkBg"
              onClick={prev}
            >
              <PhosphorIcon
                name={"caret-left"}
                fontSize={14}
                class="font-semibold"
                size="bold"
              ></PhosphorIcon>
            </button>
            <button
              class="flex h-7 w-7 cursor-pointer items-center justify-center rounded-lg border border-baseTertiaryDark p-2 hover:border-darkBg"
              onClick={next}
            >
              <PhosphorIcon
                name={"caret-right"}
                fontSize={14}
                class="font-semibold"
                size="bold"
              ></PhosphorIcon>
            </button>
          </div>
        </div>
      </Show>
      <div use:slider class="flex overflow-hidden">
        <For each={bannersToDisplay}>
          {(banner) => {
            return (
              <div
                class={`flex cursor-pointer items-center justify-center`}
                onClick={() => {
                  if (banner.productId) {
                    openVoucherL2({ productId: banner.productId });
                  } else if (banner.categoryName) {
                    if (banner.categoryName === "All") {
                      openSearchPage();
                    } else {
                      selectASearchCategory(banner.categoryName);
                    }
                  }
                }}
              >
                <Show
                  when={props.mode === "hubble" && banner.link}
                  fallback={
                    <img
                      src={banner.imageUrl}
                      class="max-h-[350px] px-[20px] py-[10px]"
                    />
                  }
                >
                  <A href={banner.link!} target="_blank">
                    <img
                      src={banner.imageUrl}
                      class="max-h-[350px] px-[20px] py-[10px]"
                    />
                  </A>
                </Show>
              </div>
            );
          }}
        </For>
      </div>
      <Show when={bannersToDisplay!.length > 1 && props.showFooter}>
        <ul class={`mt-1 flex justify-center gap-2 [list-style:none]`}>
          <For each={bannersToDisplay}>
            {(_, index) => (
              <li
                class={`inline-block h-2 w-2 cursor-pointer rounded-md content-[""] [transition:all_300ms_ease] ${current() === index() ? "w-4 bg-black" : "bg-gray-300"}`}
                onClick={() => moveTo(index())}
              ></li>
            )}
          </For>
        </ul>
      </Show>
    </Show>
  );
}
